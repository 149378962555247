import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function CardInfo(props) {
  return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm container spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" component="div">
              {props.icono}
            </Typography>
          </Grid>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {props.title}
              </Typography>
              <div style={{height:2, width:25, backgroundColor:'#e6af2a', marginBottom:12}}/>
              <Typography variant="body2" gutterBottom>
                {props.description}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {props.description2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}