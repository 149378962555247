
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import logo from '../../assets/img/pharos/logos/White_pharos_logoHorizontal_Retina_alpha.png'

function DemoFooter() {
  return (
    <footer className="footer footer-black" style={{backgroundColor: '#1E1E1E'}}>
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <span className="copyright">
                  © {new Date().getFullYear()}, Pharos Payments{" "}
                 </span>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <img style={{width:100, height:30}} src={logo}/>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
