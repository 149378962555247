
import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from '../../assets/img/pharos/logos/pci_dss.png';
import Fade from "react-reveal/Fade";
// reactstrap components

// core components

function SectionDownload() {
  return (
    <>
      <div className="section section-dark " style={{backgroundColor:"#eee"}}>

        <div style={{display:'flex', justifyContent:'space-between', padding: 45, backgroundColor: '#eee' }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }} style={{marginLeft:25}}>
              <Grid item xs container direction="column-reverse" spacing={2} xs={12} sm={6} md={4} lg={4}>
                <Grid item xs >
                  <Fade left delay={700}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                      {"NOSOTROS"}
                    </Typography>
                  </Fade>
                  <div style={{height:2, width:25, backgroundColor:'#e6af2a', marginBottom:12}}/>
                  <Fade left delay={700}>
                    <Typography variant="body2" gutterBottom>
                      {"Somos la solución para aceptar pagos con tarjetas de cualquier tipo (débito, crédito, chip, banda) de todos los bancos, tanto tarjeta presente como no presente."}
                    </Typography>
                  </Fade>
                </Grid>
              </Grid>
              
          </Grid>
          <div style={{ marginRight:25}}>
            <Fade right delay={700}>
              <img style={{width:150, height:150}} src={logo}/>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionDownload;
