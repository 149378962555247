
import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from 'react-reveal/Fade';

import logo1 from '../../assets/img/pharos/logos/discover-logo-of-pay-system-150x150.png'
import logo2 from '../../assets/img/pharos/logos/master-card-logo-150x150.png'
import logo3 from '../../assets/img/pharos/logos/visa-pay-logo-150x150.png'
import logo4 from '../../assets/img/pharos/logos/american-express-logo-150x150.png'
import logo5 from '../../assets/img/pharos/logos/unionpay-logo-150x150.png'

// plugin that creates slider
function SectionButtons() {
  
  return (
    <>
          <div className="section section-dark " style={{backgroundColor:"white"}}>

      <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 style={{ fontSize:28, fontFamily:"Open Sans"}}>ACEPTAMOS TODAS LAS TARJETAS</h2>
              <p >
              Ayudamos en la operativa de cobro a todo tipo de negocios.
Tus clientes necesitan formas de pago más rápidas y eficientes, ofréceles diferentes maneras de hacerlo a través de un solo proveedor.


              </p>
            </Col>
          </Row>
        <div style={{display:'flex', justifyContent:'center', borderRadius:7, flexWrap:'wrap', marginBottom:20, marginTop:20}}>
            <Fade left delay={500}><img style={{margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4}} src={logo1}/></Fade>
            <Fade left delay={500}><img style={{margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4}} src={logo2}/></Fade>
            <Fade top delay={550}><img style={{margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4}} src={logo3}/></Fade>
            <Fade right delay={500}><img style={{margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4}} src={logo4}/></Fade>
            <Fade right delay={500}><img style={{margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4}} src={logo5}/></Fade>
        </div>
      </Container>
    </div>
    </>
  );
}

export default SectionButtons;
