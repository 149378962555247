
import React from "react";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Zoom from 'react-reveal/Zoom';
import Grid from '@mui/material/Grid';
import CardInfo2 from './CardInfo2';

function SectionNavbars() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
      <div style={{display:'flex', justifyContent:'center', marginTop:40, marginBottom:15, fontSize:28, fontFamily:"Open Sans" , color:"white"}}>
          NUESTROS SERVICIOS
        </div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:15, marginLeft:25, marginRight:25}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={500}>
                  <CardInfo2 icono={<FmdGoodIcon/>} title={"ES FACIL Y RAPIDO"} description={"Con nuestra intuitiva aplicación, aumenta los ingresos de tu negocio."} description2={" "}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={600}>
                  <CardInfo2 icono={<FmdGoodIcon/>} title={"TODAS LAS TARJETAS"} description={"Aceptamos todas las tarjetas"} description2={"para todo tipo de negocios."}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={700}>
                  <CardInfo2 icono={<FmdGoodIcon/>} title={"SEGURIDAD"} description={"Somos Agregadores de Medios de Pagos"} description2={"regulados por la CNBV, además de PCI compliant"}/>
                </Zoom>
              </Grid>
            </Grid>
        </div> 
      </div>
    </>
  );
}

export default SectionNavbars;
