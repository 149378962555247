
import React from "react";
import { Container, Row, Col } from "reactstrap";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// plugin that creates slider
function SectionInfo(props) {
  
  return (
    <>
          <div className="section section-dark " style={{backgroundColor:"white"}}>

            <Grid container spacing={2}>
              <Grid item xs={12} sm container spacing={1}>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs style={{justifyContent:'center', alignSelf:'center'}}>
                    <div style={{display:"flex", alignItems:'center', justifyContent:'center', margin:10}}>
                      <FmdGoodIcon/>
                    </div>
                    <div style={{height:2, width:'auto', backgroundColor:'#e6af2a', marginBottom:12}}/>
                    <Typography variant="body2" gutterBottom>
                      {"CIUDAD DE MÉXICO, MÉXICO"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs style={{justifyContent:'center', alignSelf:'center'}}>
                    <div style={{display:"flex", alignItems:'center', justifyContent:'center', margin:10}}>
                      <MailOutlineIcon />
                    </div>
                    <div style={{height:2, width:'auto', backgroundColor:'#e6af2a', marginBottom:12}}/>
                    <Typography variant="body2" gutterBottom>
                      {"INFO@PHAROSPAYMENTS.COM"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
    </div>
    </>
  );
}

export default SectionInfo;