import React from "react";
import Fade from 'react-reveal/Fade';
import Laptop from '../../assets/img/pharos/logos/lap.png'
import Aws from '../../assets/img/pharos/logos/AWS.png'

// reactstrap components
import { Container } from "reactstrap";

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          padding:40,
          backgroundImage:
            "url(" + require("assets/img//pharos/faro5_ret-blackwhite-e1578154560997.png").default + ")",
        }}
      >
        <Container>
        <div style={{width:'100%', height:'100%'}}>
            <div style={{ display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column', marginTop:10}}>
              <Fade left>
                <h1 style={{fontFamily:'sans-serif', fontSize:40}}>PLATAFORMA DE PAGOS</h1>
              </Fade>
              <Fade right delay={1000}>
                  <h2>
                    Soluciones de Pago 
                  </h2>
              </Fade>
            </div>
           

            <div style={{display:'flex', bottom:0, marginTop:30, alignItems:'flex-end'}}>
              <div>
                <Fade right delay={2500} >
                    <img src={Laptop} style={{width:'100%', height:'auto'}} />
                </Fade>

              </div>
              <div style={{display:'flex', justifyContent:'end',padding:5}}>
                <Fade right delay={3000}>
                  <img src={Aws} style={{width:'50%', height:'auto'}}/>
                </Fade>
              </div>
            </div>

        </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
