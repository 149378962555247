
import React from "react";

import { Container, Row, Col } from "reactstrap";
import Zoom from 'react-reveal/Zoom';

import banco1 from '../../assets/img/pharos/logos/logo_bancos/banco1.png'
import banco2 from '../../assets/img/pharos/logos/logo_bancos/bancoa2.png'
import banco3 from '../../assets/img/pharos/logos/logo_bancos/banco3.png'
import bancoa4 from '../../assets/img/pharos/logos/logo_bancos/bancoa4.png'
import banco5 from '../../assets/img/pharos/logos/logo_bancos/banco5.png'
import banco6 from '../../assets/img/pharos/logos/logo_bancos/banco6.png'
import banco7 from '../../assets/img/pharos/logos/logo_bancos/bancoa7.png'
import banco8 from '../../assets/img/pharos/logos/logo_bancos/banco8.png'
import banco9 from '../../assets/img/pharos/logos/logo_bancos/banco9.jpg'
import banco10 from '../../assets/img/pharos/logos/logo_bancos/banco10.png'
import banco11 from '../../assets/img/pharos/logos/logo_bancos/banco11.png'
import banco12 from '../../assets/img/pharos/logos/logo_bancos/banco12.png'
import banco13 from '../../assets/img/pharos/logos/logo_bancos/banco13.png'
import banco14 from '../../assets/img/pharos/logos/logo_bancos/banco14.png'
import banco15 from '../../assets/img/pharos/logos/logo_bancos/banco15.png'
import banco16 from '../../assets/img/pharos/logos/logo_bancos/banco16.png'
import banco17 from '../../assets/img/pharos/logos/logo_bancos/banco17.png'
function SectionDark() {
  const style= {
    margin: 5, width:100, height:100, boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)",padding:5, borderRadius:4, backgroundColor:'#FFFFFF', objectFit: 'contain'
  }
  return (
    <>
      <div className="section section-dark">
      <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 style={{ fontSize:28, fontFamily:"Open Sans", color:'#FFFFFF'}}>MESES SIN INTERESES</h2>
              <p className="description">

              </p>
            </Col>
          </Row>
          <div style={{display:'flex', justifyContent:'center', borderRadius:7, flexWrap:'wrap', marginBottom:20}}>
          
            <Zoom top delay={1000}><img style={style} src={banco1}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco2}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco3}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={bancoa4}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco5}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco6}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco7}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco8}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco9}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco10}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco11}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco12}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco13}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco14}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco15}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco16}/></ Zoom>
            <Zoom top delay={1000}><img style={style} src={banco17}/></ Zoom>

          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionDark;
