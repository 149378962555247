import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);

export default function CardInfo2(props) {
  return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm container spacing={1}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
             {/*  <div style={{display:"flex", alignItems:'center', justifyContent:'center'}}>
                {props.icono}
              </div> */}
              <WhiteTextTypography gutterBottom variant="subtitle1" component="div">
                {props.title}
              </WhiteTextTypography>
              <div style={{height:2, width:"auto", backgroundColor:'#e6af2a', marginBottom:12}}/>
              <WhiteTextTypography variant="body2" gutterBottom>
                {props.description}
              </WhiteTextTypography>
              <WhiteTextTypography variant="body2" gutterBottom>
                {props.description2}
              </WhiteTextTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}