
import React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Zoom from 'react-reveal/Zoom';
import Grid from '@mui/material/Grid';
import CardInfo from './CardInfo';

export default function SectionNavigation() {
  return (
    <>
    <div className="section section-dark " style={{backgroundColor:"white"}}>

        <div style={{display:'flex', justifyContent:'center', marginTop:40, marginBottom:15, fontSize:28, fontFamily:"Open Sans"}}>
          NUESTROS SERVICIOS
        </div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:15, marginLeft:25, marginRight:25}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={500}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"PHAROS / RESTAURANTE"} description={"Contamos con toda la operativa necesaria para el cobro en restaurantes, incluyendo propina"}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={600}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"HOTELERÍA"} description={"El cargo previo, con la pre-autorización y el cargo final sobre la misma tarjeta están resueltos en nuestra operativa de hotelería. (CHECK IN/CHECK-OUT)"}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={700}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"RENTA DE COCHES"} description={"Reserva de dinero para la renta de coches, implica una operativa particular que nuestro pasarela de pagos resuelve satisfactoriamente para este sector (PRE-VENTA)"}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={800}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"RETAIL"} description={"Cobros, anulaciones y devoluciones parciales están soportadas para cubrir la operativa del sector retail."}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={900}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"CARGOS RECURRENTES"} description={"Los cargos recurrentes son cobros automatizados que se realizan periódicamente (diario, mensual, anual). Están relacionados a cargos por consumo de productos o servicios como membresías, suscripciones, pólizas o recibos con valor fijo; que previamente fueron autorizados por el cliente."}/>
                </Zoom>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} >
                <Zoom delay={1000}>
                  <CardInfo icono={<FmdGoodIcon/>} title={"MENSUALIDADES"} description={"3, 6, 9 y 12 mensualidades son aceptadas para todas las tarjetas presentes "}/>
                </Zoom>
              </Grid>
            </Grid>
        </div>
      </div>
    </>
    
  );
}